<template>
    <div>
        <Dialog
            :visible.sync="showValidationErrorDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            @update:visible="continueFilling(true)"
        >
            <template #header>
                <h3 class="p-mb-0">Ошибка валидации</h3>
            </template>
            <div class="p-dialog-content p-d-flex p-px-0 layerContainer">
                <div class="p-d-flex p-flex-column" v-show="validationError.cell">
                    <div class="p-inputgroup" v-html="validationError.cell" style="flex-wrap: wrap; line-height: 18px; margin-bottom: 4px;"></div>
                    <div class="p-inputgroup" v-html="validationError.message" style="flex-wrap: wrap; line-height: 18px; margin-bottom: 16px;"></div>
                </div>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-danger p-button-secondary" type="button" @click="continueFilling(true)">
                        <span class="p-button-label">Отменить изменения</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'validationErrorDialog',

    mixins: [ requestToastHandler ],

    props: {
        showValidationErrorDialog: {
            require: true,
            type: Boolean
        },
        validationError: {
            type: Object
        }
    },

    emits: [ 'continueFilling' ],

    data() {
        return {};
    },

    methods: {
        continueFilling(cancelChanges = false) {
            this.$emit('continueFilling', {
                row: this.validationError.row,
                column: this.validationError.column,
                cancelChanges
            });
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .cell-marker {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #495058;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block !important;
    background: #C5E0C8;
    padding: 2px;
    margin: 1px;
}
</style>
