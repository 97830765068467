import { apiUrl, axios } from '@/api/api';
import { PAGE_SIZE } from '@/constants/common';
import { downloadFile, generateErrors } from '@/main/utils/common';

export const getReportTemplates = async ({
    formId,
    page = 1,
    pageSize = PAGE_SIZE,
    include = 'file,createdBy,updatedBy',
    filter = {}
}) => {
    try {
        const { data } = await axios.get(`${apiUrl}/form/${formId}/relationships/report-templates`, { params: {
            page,
            'page-size': pageSize,
            filter: JSON.stringify(filter),
            include
        }});

        return data;
    } catch (error) {
        generateErrors(error);
    }
}

export const createReportTemplate = async (formId, data) => {
    try {
        const { data: response } = await axios.post(`${ apiUrl }/form/${ formId }/relationships/report-templates`, data);
        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export const updateReportTemplate = async (reportId, data) => {
    try {
        const { data: response } = await axios.patch(`${ apiUrl }/form-report-template/${ reportId }`, data);
        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export const downloadReportTemplate = async (reportId, fileName) => {
    try {
        const { data } = await axios.post(`${ apiUrl }/form-report-template/${ reportId }/download`, {}, {
            responseType: 'blob'
        });
        downloadFile(fileName, data);
    } catch (error) {
        generateErrors(error);
    }
}

export const deleteReportTemplate = async (reportId) => {
    try {
        const { data: response } = await axios.delete(`${ apiUrl }/form-report-template/${ reportId }`);
        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export const getFormReports = async (page = 1, pageSize = PAGE_SIZE, filter = {}, sort = '') => {
    try {
        const { data } = await axios.get(`${ apiUrl }/form-report`, {
            params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter),
                include: 'form,file,createdBy.organization,signedBy,signedFile,signedBy.organization',
                sort: sort
            }
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
}

export const signDocument = async (reportId, data) => {
    try {
        return await axios.post(`${ apiUrl }/form-report/${ reportId }/sign`, data);
    } catch (error) {
        generateErrors(error);
    }
}
