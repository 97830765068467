<template>
    <div>
        <Dialog
            :visible.sync="showEditedErrorDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            @update:visible="acceptValue(false)"
        >
            <template #header>
                <h3 class="p-mb-0">Значение изменилось</h3>
            </template>
            <div class="p-dialog-content p-d-flex p-px-0 layerContainer">
                <div class="p-d-flex p-flex-column" v-if="editedError?.cell">
                    <div class="p-inputgroup" v-html="editedError.cell" style="flex-wrap: wrap; line-height: 18px; margin-bottom: 4px;"></div>
                    <div class="p-d-inline-block p-py-1">
                        Данные в редактируемой ячейке изменились.
                    </div>
                    <div class="p-d-flex p-justify-between p-py-2">
                        <div class="p-d-flex p-flex-column">
                            Новое значение: {{ editedError.value }}
                        </div>
                        <div class="p-d-flex p-flex-column">
                            Старое значение: {{ editedError.oldValue }}
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-primary" type="button" @click="acceptValue(true)">
                        <span class="p-button-label">Обновить таблицу</span>
                    </Button>
                    <Button class="p-button p-button-danger p-button-secondary" type="button" @click="acceptValue(false)">
                        <span class="p-button-label">Вернуть старое значение</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'editedErrorDialog',

    mixins: [ requestToastHandler ],

    props: {
        showEditedErrorDialog: {
            require: true,
            type: Boolean
        },
        editedError: {
            type: Object
        }
    },

    emits: [ 'acceptValue' ],

    data() {
        return {};
    },

    methods: {
        acceptValue(acceptChanges = false) {
            this.$emit('acceptValue', {
                row: this.editedError.row,
                column: this.editedError.column,
                type: this.editedError.type,
                value: this.editedError.value,
                acceptChanges
            });
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .cell-marker {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #495058;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block !important;
    background: #C5E0C8;
    padding: 2px;
    margin: 1px;
}
</style>
