<template>
    <div>
        <Accordion :multiple="true" :activeIndex="active" @tab-open="toggleAccordion($event)" ref="accordion">
            <AccordionTab v-for="group in groupSet" :key="group.id" :id="group.id"
                          v-bind:class="{ nulableHeight: group.id === 'noId' }">
                <template #header>
                    <div class="p-d-flex p-flex-column" style="width: 100%;">
                        {{ group.name }}
                        <div class="p-mt-2 status">
                           <span class="p-mt-2 status-label" :style="`background: ${ group.statusInfo?.bgColor }; color: ${ group.statusInfo?.textColor };`">{{ group.statusInfo.label }}</span>
                        </div>
                    </div>
                </template>
                <div v-for="(list, index) in group.lists" :key="list.id"
                    :class="[index % 2 !== 0 ? 'greyBg' : '', 'userContainer', 'p-d-flex', 'p-ai-center']"
                    @click="listSelect(list.id, list.name)">
                    <div class="p-d-flex p-flex-row" style="width: 100%;">
                        <span v-if="list.empty" class="pi pi-file"></span>&nbsp;
                        <span :class="{ bold: worksheetId === list.id }">{{ list.name }}</span>
                    </div>
                    <div class="p-d-flex">
                        <div class="p-mt-2 status">
                            <span class="p-mt-2 status-label" :style="`background: ${ list.statusInfo?.bgColor }; color: ${ list.statusInfo?.textColor };`">{{ list.statusInfo?.label }}</span>
                        </div>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
export default {
    name: 'lists',
    props: {
        groups: {
            type: Array,
            require: true,
        },
        worksheetId: {
            type: String
        }
    },
    emits: ['listSelect'],
    data() {
        return {
            groupSet: this.groups,
            active: 0,
        };
    },
    methods: {
        openNoIdGroup() {
            this.$refs.accordion.$children &&
                this.$refs.accordion.$children.map((children) => {
                    if (children.$attrs.id === 'noId' && children.d_active === false) children.onTabClick();
                });
        },
        listSelect(listId = null, listTitle = null) {
            this.$emit('listSelect', { listId: listId, listTitle: listTitle});
        },
        toggleAccordion(event) {
            this.currentGroupId = event.tab.$attrs.id;
        },
    },
    watch: {
        groups: {
            handler(to) {
                if (to) {
                    this.groupSet = this.groups;
                    this.openNoIdGroup();
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.openNoIdGroup();
    },
};
// 60
</script>

<style lang="scss" scoped>
.bold {
    font-weight: 700 !important;
}
.overflow {
    overflow: auto;
}
.greyBg {
    background: #f5f5f5;
}

::v-deep .listBox {
    .p-listbox-list {
        padding-top: 0;
        li {
            padding: 0;
        }
    }
}
::v-deep .redLastListElement {
    li:last-child {
        span {
            color: red !important;
        }
    }
}
::v-deep .p-dialog {
    width: 480px !important;
    .p-dialog-header {
        padding: 24px;
        .p-dialog-header-icon:focus {
            box-shadow: none;
        }
        h3 {
            font-size: 18px;
            color: #272727;
        }
    }
    .p-dialog-content {
        padding: 0 24px;

        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
        }
        .p-field {
            margin-bottom: 0;
        }
        .errorPos {
            position: relative;
        }
        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 4px;
        }
    }

    .p-inputtext {
        padding: 8px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
    }
    .p-dialog-footer {
        padding: 0 24px 24px 24px;
        button {
            margin: 0 12px 0 0;
        }
    }
    .customWidth {
        width: 208px !important;
    }
    .p-pr8 {
        margin-right: 16px;
    }

    .maxWidth {
        max-width: 100%;
        flex-wrap: nowrap !important;
        justify-content: space-between;
    }
}
::v-deep .delDialog {
    width: 480px !important;
    box-shadow: 0 2px 8px rgba(82, 82, 82, 0.16);
    border-radius: 2px;
    .p-dialog-content.p-ai-center.p-d-flex {
        padding-left: 0;
        padding-right: 1rem;
    }
    .contentMargin {
        margin-bottom: 16px;
    }
}
::v-deep .p-accordion {
    .p-accordion-header .p-accordion-header-link {
        padding: 6px 35px 6px 32px;
        background: transparent;
        color: #272727;
        line-height: 125%;
        font-weight: 500;
        font-size: 13px;
        min-height: 34px;
        border-left: none;
        border-right: none;
        border-radius: 0 !important;
        .p-accordion-toggle-icon {
            font-size: 12px;
            position: absolute;
            left: 11px;
            top: 11px;
        }
        .p-panel-header-icon {
            position: absolute;
            right: 8px;
            top: 7px;
        }
    }
    .p-accordion-header {
        .status-label {
            padding: 4px;
            border-radius: 4px;
        }
        .p-panel-header-icon {
            visibility: hidden;
        }
    }
    .p-accordion-header:hover {
        .p-panel-header-icon {
            visibility: visible;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: rgba(135, 148, 163, 0.25);
        }
    }
    // .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    //     display: none;
    // }
    .p-accordion-header-text {
        width: 90%;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
    }
    .p-accordion-content {
        padding: 0;
        min-height: 30px;
        border: none;
        .userContainer {
            cursor: pointer;
        }
        .status-label {
            padding: 4px;
            border-radius: 4px;
        }
    }
    .p-accordion-tab-active {
        .p-accordion-header .p-accordion-header-link {
            background: transparent;
        }
    }
}
::v-deep .userContainer {
    padding: 8px 10px 8px 10px !important;
    height: 32px;
    font-size: 13px;
    line-height: 16px;

    color: #272727;
    .p-panel-header-icon {
        visibility: hidden;
        width: 24px;
        height: 24px;
    }
    span {
        max-width: 190px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.userContainer:hover {
    background: #e0e0e0;
    .p-panel-header-icon {
        visibility: visible;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(135, 148, 163, 0.25);
    }
}
::v-deep .nulableHeight {
    .p-accordion-header {
        display: none;
    }
}
</style>
