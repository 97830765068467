<template>
    <ScrollPanel class="customStyles custom">
        <Card v-for="(history, index) in historySet"
            :key="history.id">
            <template #content>
                <div :class="[index === 0 ? 'boldText' : '' , index % 2 !== 0 ? 'greyBg' : '', 'userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-flex-column">
                        <div>{{ history.date | dateTime }}</div>
                        <div>{{ history.name || 'Имя не указано' }}</div>
                    </div>
                    <button class="p-panel-header-icon p-link" @click.stop="showListMenu($event, history.id, `listMenu${ history.id }`)">
                        <span class="pi pi-ellipsis-h"></span>
                    </button>
                    <Menu id="history_menu" class="redLastListElement" :ref="`listMenu${ history.id }`" :model="listMenuItems" :popup="true" />
                </div>
            </template>
        </Card>
    </ScrollPanel>
</template>

<script>
import ScrollPanel from 'primevue/scrollpanel';

export default {
    name: 'history',
    props: {
        worksheetHistory: {
            require: true,
            type: Array,
        },
        canEdit: {
            type: Boolean,
            require: true
        },
        canReport: {
            type: Boolean,
            require: true
        },
        status: {
            type: String,
            require: true
        }
    },
    emits: [ 'showHistoryVersion', 'restore', 'download' ],
    data() {
        return {
            historySet: this.worksheetHistory,
            currentHistoryId: null,
            listMenuItems: [
                {
                    label: 'Просмотр',
                    icon: 'pi pi-pencil',
                    command: ($event) => {
                        $event.originalEvent.stopPropagation();
                        this.showHistoryVersion($event);
                    },
                },
                {
                    label: 'Восстановить',
                    icon: 'pi icon custom-status-change',
                    command: ($event) => {
                        $event.originalEvent.stopPropagation();
                        this.restore($event);
                    },
                    visible: ['new', 'in_work'].includes(this.status)
                },
                {
                    label: 'Скачать',
                    icon: 'pi pi-fw pi-download',
                    command: ($event) => {
                        $event.originalEvent.stopPropagation();
                        this.download($event);
                    },
                    visible: this.canReport
                }
            ],
        };
    },
    components: {
        ScrollPanel,
    },
    methods: {
        async showListMenu(event, listId, index) {
            Object.keys(this.$refs)
                .map((key) => {
                    this.$refs[`${key}`][0] && this.$refs[`${key}`][0].hide(event);
                });
            const menuEl = this.$refs[index][0];

            // Open new popup
            menuEl.toggle(event);
            await this.$nextTick();
            const { y } = event;
            const { $el } = menuEl;

            if ($el.style) {
                $el.style.top = `${ y - 115 }px`;
            }
            $el.style.left = '15px';
            this.currentHistoryId = listId;
        },

        showHistoryVersion() {
            this.$emit('showHistoryVersion', this.currentHistoryId);
        },

        restore() {
            this.$emit('restore', this.currentHistoryId);
        },

        download() {
            this.$emit('download', this.currentHistoryId);
        }
    },

    watch: {
        worksheetHistory: {
            handler(to) {
                if (to) {
                    this.historySet = this.worksheetHistory;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customStyles {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 10;
}
::v-deep .p-scrollpanel-content {
    overflow: hidden;
    padding-bottom: 100px;
}

::v-deep .p-scrollpanel {
    &.custom {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-b);
            border-bottom: 9px solid var(--surface-b);
        }

        .p-scrollpanel-bar {
            background-color: red;
            border-radius: 0;
            opacity: 1;
            transition: background-color 0.2s;
        }

        .p-scrollpanel-bar-y {
            width: 6px;
            display: block;
        }
    }
}
.userContainer {
    align-items: start;
    padding: 8px;

    & .p-panel-header-icon {
        visibility: hidden;
    }

    &:hover {
        .p-panel-header-icon {
            visibility: visible;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: rgba(135, 148, 163, 0.25);
        }
    }
}

.greyBg {
    background: #f5f5f5;
}

.history-button {
    z-index: 1;
    margin-top: 8px;
    margin-right: 8px;
}

::v-deep .p-card {
    box-shadow: none;

    & .p-card-body {
        border-radius: 0;
        padding: 0 !important;
        border: 1px solid #eaeaea;
    }

    & .p-card-content {
        padding: 0 !important;
        font-size: 13px;
    }
}
</style>
