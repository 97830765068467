import { apiUrl, axios } from '@/api/api.js';
import { downloadFile, generateErrors } from '@/main/utils/common';

export const sendAggregateUnloadingData = async (formAggregateId, reportTemplateId) => {
    try {
        return await axios.post(`${apiUrl}/form-aggregate/${formAggregateId}/report/${reportTemplateId}`);
    } catch (error) {
        generateErrors(error);
    }
};

export const getAggregateData = async (formAggregateId) => {
    try {
        const {
            data: { data, included },
        } = await axios.get(`${apiUrl}/form-aggregate/${formAggregateId}`);

        return {
            data: data,
            included: included,
        };
    } catch (error) {
        generateErrors(error);
    }
};

export const createSlice = async (formAggregateId, worksheetId, type, number) => {
    try {
        const {
            data: { data: result },
        } = await axios.get(`${apiUrl}/form-aggregate/${formAggregateId}/slice?worksheetId=${worksheetId}&${type}=${number}`);

        return result;
    } catch (error) {
        generateErrors(error);
    }
};

export const downloadSlice = async (formAggregateId, worksheetId, type, number) => {
    try {
        const result = await axios.get(`${apiUrl}/form-aggregate/${formAggregateId}/slice/xlsx?worksheetId=${worksheetId}&${type}=${number}`, {
            responseType: 'blob',
        });

        downloadFile(formAggregateId, result.data);
    } catch (error) {
        generateErrors(error);
    }
};

export const getAggregateCheck = async (formAggregateId, worksheets) => {
    try {
        return await axios.get(`${apiUrl}/form-aggregate/${formAggregateId}/check?${worksheets}`);
    } catch (error) {
        generateErrors(error);
    }
};

export const downloadAggregateReport = async (formAggregateId, reportTemplateId) => {
    try {
        const response = await axios.post(`${apiUrl}/form-aggregate/${formAggregateId}/report/${reportTemplateId}`);
        return response;
    } catch (error) {
        generateErrors(error);
    }
};
