<template>
    <ScrollPanel class="customStyles custom">
        <div id="printList">
            <h3 class="p-mb-2 printHeader">
                <div>Ошибки. {{ formTitle }}</div>
                Лист {{ worksheetTitle }}
            </h3>
            <Card v-show="status.length && status !== 'finish' && status !== 'clear'">
                <template #content>
                    <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                        <div class="p-d-flex status p-ai-center">
                            <span class="status-label calculated">Выполняется проверка листа</span>
                        </div>
                    </div>
                </template>
            </Card>
            <Card v-if="status === 'clear'">
                <template #content>
                    <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                        <div class="p-d-flex p-flex-column">
                            <span class="status-label" style="background: rgb(255, 103, 103); padding: 6px; border-radius: 4px;">
                                Данные обновились<br/>
                                Требуется проверка листа
                            </span>
                        </div>
                    </div>
                </template>
            </Card>
            <Card v-if="!status.length && 0 === errorsSet?.length">
                <template #content>
                    <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                        <div class="p-d-flex p-flex-column">
                            <span class="status-label" style="background: rgb(255, 103, 103); padding: 6px; border-radius: 4px;">Требуется проверка листа</span>
                        </div>
                    </div>
                </template>
            </Card>
            <Card
                v-for="(item, index) in errorsSet"
                :key="index"
            >
                <template #content>
                    <div :class="[index % 2 !== 0 ? 'greyBg' : '', 'userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                        <div class="p-d-flex p-flex-column">
                            <span class="error-description" v-html="item.cell" @click="showErrorCell($event)"></span>
                            <span>{{ item.message }}</span>
                            <span class="error-description" v-html="item.formula" @click="showErrorCell($event)"></span>
                            <span class="error-expain">{{ item.explain }}</span>
                        </div>
                    </div>
                </template>
            </Card>
            <Card v-if="0 === errorsSet?.length && status === 'finish'">
                <template #content>
                    <div :class="['userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                        <div class="p-d-flex p-flex-column">
                            <span>Ошибок не обнаружено</span>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </ScrollPanel>
</template>

<script>

export default {
    name: 'mistakes',

    props: {
        worksheetErrors: {
            require: true,
            type: Array
        },
        errorStatus: {
            type: String
        },
        formTitle: {
            require: true,
            type: String
        },
        worksheetTitle: {
            require: true,
            type: String
        }
    },

    emits: [ 'showErrorCell' ],

    data() {
        return {
            status: this.errorStatus,
            errorsSet: this.worksheetErrors,
        }
    },

    methods: {
        showErrorCell(event) {
            this.$emit('showErrorCell', {
                row: +event.target.dataset.row,
                column: +event.target.dataset.column
            });
        },

        updateErrors() {
            this.status = this.errorStatus;
            this.errorsSet = this.worksheetErrors;
        },

        printErrors() {
            const prtHtml = document.getElementById('printList').innerHTML;
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');

            WinPrint.document.write(`
<!DOCTYPE html>
<html>
    <head>
        <style>
            .p-flex-column {
                flex-direction: column !important;
            }
            .p-d-flex {
                display: flex !important;
            }
            .customStyles {
                width: 100%;
                max-height: 100%;
                overflow: auto;
                z-index: 10;
            }

            .userContainer {
                align-items: start;
                background: #fff;
                padding: 1rem;
            }

            .greyBg {
                background: #f5f5f5;
            }

            .error-description {
                font-weight: 500;
                margin: 4px 0;
            }

            .p-card {
                box-shadow: none;
            }

            .p-card .p-card-body {
                border-radius: 0;
                padding: 0 !important;
                border: none;
            }

            .p-card .p-card-content {
                padding: 0 !important;
                font-size: 13px;
            }

            .cell-marker {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #495058;
                border-radius: 3px;
                cursor: pointer;
                display: inline-block !important;
                background: #FF8F8F;
                padding: 2px;
                margin: 1px;
            }

            .green .cell-marker {
                background: #C5E0C8;
            }

            .error-expain {
                color: #F44336;
            }

            .printHeader {
                display: none;
            }

            @media print {
                .printHeader {
                    display: block;
                }
            }
        </style>
    </head>
    <body>
        ${prtHtml}
    </body>
</html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        }
    },

    watch: {
        errorStatus: {
            handler(to) {
                if (to) {
                    this.status = this.errorStatus;
                }
            }
        },
        worksheetErrors: {
            handler(to) {
                if (to) {
                    this.updateErrors();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customStyles {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 10;
}

.status {
    color: #8794a3;
    white-space: nowrap;

    .status-label {
        background: #ffe6a5;
        padding: 6px;
        border-radius: 4px;
        color: #495058;
    }
}

::v-deep .p-scrollpanel {

    &.custom {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-b);
            border-bottom: 9px solid var(--surface-b);
        }

        .p-scrollpanel-bar {
            background-color: red;
            border-radius: 0;
            opacity: 1;
            transition: background-color 0.2s;
        }

        .p-scrollpanel-bar-y {
            width: 6px;
        }

        .p-button {
            margin-top: 4px;
        }
    }
}

.userContainer {
    align-items: start;
    background: #fff;
    padding: 1rem;
    justify-content: center;
}

.greyBg {
    background: #f5f5f5;
}

.error-description {
    font-weight: 500;
    margin: 4px 0;
}

::v-deep .p-card {
    box-shadow: none;

    & .p-card-body {
        border-radius: 0;
        padding: 0 !important;
        border: none;
    }

    & .p-card-content {
        padding: 0 !important;
        font-size: 13px;
    }
}

::v-deep .cell-marker {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #495058;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block !important;
    background: #C5E0C8;
    padding: 2px;
    margin: 1px;
}

.error-expain {
    color: #F44336;
}

::v-deep .nulableHeight {

    .p-accordion-header {
        display: none;
    }
}

::v-deep .p-accordion .p-accordion-content {
    padding: 0;
}

.printHeader {
    display: none;
}

@media print {
    .printHeader {
        display: block;
    }
}
</style>
