<template>
    <div>
        <Dialog :visible.sync="showDialog" header="" position="center" :modal="true" :closeOnEscape="true" :closable="true">
            <template #header>
                <h3 class="p-mb-0">Загрузить данные</h3>
            </template>
            <FileUpload class='p-mb-4' name='demo[]' :customUpload='true' :auto='true' @uploader='onUpload'
                        :maxFileSize='1000000' chooseLabel='Выберите файл' cancelLabel='Очистить' accept='*.xlsx'>
                <template #empty>
                    <p>Перетащите сюда файл для загрузки</p>
                </template>
            </FileUpload>

            <template #footer>
                <div class='p-d-flex p-jc-start'>
                    <Button class='p-button' @click='uploadData' :disabled='!uploadFile'>
                        <span class='p-button-label'>Загрузить</span>
                    </Button>
                    <Button class='p-button p-button-outlined p-button-secondary' type='button' @click='closeDialog'>
                        <span class='p-button-label'>Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'loadDataDialog',

    props: {
        showLoadDataDialog: {
            require: true,
            type: Boolean
        },
        distributionId: {
            require: true,
            type: String
        }
    },

    mixins: [ requestToastHandler ],

    emits: [ 'loadingChange', 'uploadData' ],

    data() {
        return {
            uploadFile: null,
            showDialog: this.showLoadDataDialog,
            submitted: false,
        };
    },

    methods: {
        async uploadData() {
            this.submitted = true;

            if (!this.uploadFile) {
                return;
            }

            this.$emit('uploadData', this.uploadFile);
            this.uploadFile = null;
            this.closeDialog();
        },

        onUpload(event) {
            const fD = new FormData();
            fD.append('file', event.files[0]);
            this.uploadFile = fD;
        },

        closeDialog() {
            this.showDialog = false;
            this.uploadFile = null;
            this.submitted = false;
        },
    },

    watch: {
        showLoadDataDialog: {
            handler(to) {
                if (to) {
                    this.showDialog = this.showLoadDataDialog;
                }
            }
        }
    }
}
</script>

<style scoped lang='scss'>
::v-deep .uploadItem {
    .p-button-raised,
    .p-button.p-button-danger:enabled:focus {
        box-shadow: none;
    }

    .fileNameLength {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.uploadInput {
    display: none;
}

.uploadwidth {
    width: 164px;
}

::v-deep .p-fileupload-buttonbar {
    padding: 10px;

    span:nth-child(1) {
        margin-right: 12px;
    }

    button:nth-child(2) {
        display: none !important;
    }

    .p-button {
        box-shadow: none;
    }
}
</style>
